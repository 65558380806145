import React from 'react'
import './style.scss'
import Img from 'gatsby-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons'

import CF7Basic from '../Form/CF7/Basic'

function Footer(props) {
	const image = props.image

  return (
		<footer id='master-footer' className='c0 grid-12'>

			{ image ?
				<Img fluid={image}
					imgStyle={{objectFit: 'cover'}}
					objectPosition='50% 50%'
					loading='eager'
					backgroundColor="#ccc"
					fadeIn={true}
				/>
			: null }

			<div className="details padd-3 c5 fs-85 text-left">
				<div className="content-box">
					<h6>Obszar dostawy</h6>
					<p>Firma Syty Smyk mieści się na terenie gminy Stare Babice w bliskim sąsiedztwie Warszawy. Mamy możliwość dostarczania posiłków na terenie Warszawy i jej okolic.</p>
					<p>Jeśli mają Państwo pytania i chcą dowiedzieć się więcej o naszych usługach, prosimy o kontakt.</p>
					<h6>Kontakt</h6>
					<p>
						SYTY SMYK Catering dla żłobków, przedszkoli i szkół<br />
						ul. Kraszewskiego 7, 05-082 Babice Stare<br />
						NIP: 113-218-61-72
					</p>
					<p>
						<a href="tel:516406689"><span className="fat">Tel: 516406689</span></a><br />
						<a href="mailto:sytysmyk@gmail.com"><span className="fat">E-Mail: sytysmyk@gmail.com</span></a>
					</p>
				</div>
			</div>

			<div id="contact" className="contact padd-3 c4 fs-85">
				<CF7Basic />
			</div>

			<div className='copy c0'>
				<p>Copyright &copy; 2020. Made with <FontAwesomeIcon icon={faHeart} /> by <span className='hint--rounded hint--top' data-hint='Much love!'>EvG</span>.</p>
			</div>

		</footer>
  )
}

export default Footer