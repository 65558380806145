import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'

function CF7Basic(props) {
	
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-name', form.name)
		data.append('your-phone', form.phone)
		data.append('your-email', form.email)
		data.append('your-message', form.message)
		
		const url = 'https://design-sentry.com/wp-json/contact-form-7/v1/contact-forms/7/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<form
			name="contact"
			method="post"
			action="/thanks/"
			onSubmit={handleSubmit(onSubmit)}
		>

			<h4>Masz pytanie?</h4>
			<p style={{marginTop: '-1rem'}}>Zapraszamy do kontaktu:</p>

			<label>
				<input type="text" placeholder="Imię" name="name" ref={register({required: true, maxLength: 80, message: "error message"})} />
				{errors.name && <p className="small margin-off">Imię jest wymagane</p>}
			</label>
			<label>
				<input type="text" placeholder="Telefon" name="phone" ref={register({required: false, maxLength: 80, message: "error message"})} />
				{errors.phone && <p className="small margin-off">Numer telefonu jest zbyt długi</p>}
			</label>
			<label>
				<input type="text" placeholder="e-mail" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
				{errors.email && <p className="small margin-off">Poprawny adres email jest wymagany</p>}
			</label>
			<label>
				<textarea placeholder="Wiadomość" name="message" ref={register} />
			</label>

			<button type="submit">Wyślij! {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
			
			<p className="message small">{message}</p>

		</form>
	)
}

export default CF7Basic