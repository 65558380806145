import React, {useState} from 'react'
import './style.scss'

import Img from 'gatsby-image'
import Typewriter from "typewriter-effect"
import Hero from "../Hero"

import ACFWithImage from "./ACF/WithImage"

function Content(props) {
	
	const Sections = props.wordpressPage.acf.sections_page.map((section, i) => {
		
		if(section.__typename === 'WordPressAcf_hero') {
			const image = section.slides[0].img.localFile.childImageSharp.fluid
			const content = section.slides[0].wysiwyg

			return (
				<>
					<Hero image={image} content={content} />

					{/* Point of reference for past hero observer threashold, so we can calculate if the user is past hero or not */}
					<div id="header-fold-breakpoint"></div>
				</>
			)
		}

		
		
		if(section.__typename === 'WordPressAcf_content') {
			const content = section.content
			const image = section.img?.localFile.childImageSharp.fluid
			const classes = section.classes

			return (
				<section key={`section-content-${i}`} className={`content border ${classes} ${section.color} grid-12 is-inview ${section.type}`}>

					{ image ?
						<Img fluid={image}
							imgStyle={{objectFit: 'cover'}}
							objectPosition='50% 50%'
							className="animated"
						/>
					: null }

					<div className={`content-box ${section.color}`}>
						<div className='content-holder animated padd-1' dangerouslySetInnerHTML={{__html: content}} />
					</div>

				</section>
			)
		}
	})

  return (
		<>
			{Sections}
		</>
  )
}

export default Content